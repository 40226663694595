{
  "$schema": "./node_modules/@angular/cli/lib/config/schema.json",
  "cli": {
    "analytics": false
  },
  "version": 1,
  "newProjectRoot": "projects",
  "projects": {
    "sembotAngular": {
      "projectType": "application",
      "schematics": {
        "@schematics/angular:component": {
          "style": "scss",
          "skipTests": true
        }
      },
      "root": "",
      "sourceRoot": "src",
      "prefix": "app",
      "architect": {
        "build": {
          "builder": "@angular-devkit/build-angular:application",
          "options": {
            "outputPath": {
              "base": "dist",
              "browser": ""
            },
            "index": "src/index.html",
            "polyfills": ["src/polyfills.ts"],
            "tsConfig": "tsconfig.app.json",
            "assets": ["src/favicons", "src/assets", "src/.htaccess"],
            "styles": [
              "src/styles/styles.scss",
              {
                "input": "src/styles/whitelabels/page1.co.uk/style.scss",
                "bundleName": "css.page1.co.uk",
                "inject": false
              },
              {
                "input": "src/styles/whitelabels/localhost/style.scss",
                "bundleName": "localhost",
                "inject": false
              },
              {
                "input": "src/styles/whitelabels/localhost/style.scss",
                "bundleName": "dev.sembot.io",
                "inject": false
              }
            ],
            "scripts": [
              {
                "input": "src/sembot-config.js",
                "bundleName": "sembotConfig"
              },
              "node_modules/apexcharts/dist/apexcharts.min.js"
            ],
            "allowedCommonJsDependencies": ["lodash", "zone.js/dist/zone-error", "event-source-polyfill", "export-to-csv"],
            "stylePreprocessorOptions": {
              "includePaths": ["src/styles"]
            },
            "preserveSymlinks": true,
            "extractLicenses": false,
            "sourceMap": true,
            "optimization": false,
            "namedChunks": true,
            "browser": "src/main.ts"
          },
          "configurations": {
            "production": {
              "outputPath": {
                "base": "dist/production/frontStandalone",
                "browser": ""
              },
              "index": {
                "input": "src/index.prod.html",
                "output": "index.html"
              },
              "fileReplacements": [
                {
                  "replace": "src/environments/environment.ts",
                  "with": "src/environments/environment.prod.ts"
                }
              ],
              "optimization": true,
              "outputHashing": "all",
              "sourceMap": true,
              "namedChunks": false,
              "extractLicenses": true,
              "budgets": [
                {
                  "type": "initial",
                  "maximumWarning": "6mb",
                  "maximumError": "8mb"
                },
                {
                  "type": "anyComponentStyle",
                  "maximumWarning": "7kb",
                  "maximumError": "10kb"
                }
              ]
            },
            "production-plugin": {
              "outputPath": {
                "base": "dist/production/front",
                "browser": ""
              },
              "index": {
                "input": "src/index.plugin.html",
                "output": "index.html"
              },
              "fileReplacements": [
                {
                  "replace": "src/environments/environment.ts",
                  "with": "src/environments/environment.prod.ts"
                }
              ],
              "optimization": true,
              "outputHashing": "all",
              "sourceMap": true,
              "namedChunks": false,
              "extractLicenses": true,
              "budgets": [
                {
                  "type": "initial",
                  "maximumWarning": "6mb",
                  "maximumError": "8mb"
                },
                {
                  "type": "anyComponentStyle",
                  "maximumWarning": "7kb",
                  "maximumError": "10kb"
                }
              ]
            },
            "local": {
              "outputPath": {
                "base": "dist/local/frontStandalone",
                "browser": ""
              },
              "budgets": [
                {
                  "type": "anyComponentStyle",
                  "maximumWarning": "6kb"
                }
              ],
              "fileReplacements": [
                {
                  "replace": "src/environments/environment.ts",
                  "with": "src/environments/environment.local.ts"
                }
              ]
            },
            "beta": {
              "outputPath": {
                "base": "dist/beta/frontStandalone",
                "browser": ""
              },
              "budgets": [
                {
                  "type": "anyComponentStyle",
                  "maximumWarning": "6kb"
                }
              ],
              "fileReplacements": [
                {
                  "replace": "src/environments/environment.ts",
                  "with": "src/environments/environment.beta.ts"
                }
              ],
              "outputHashing": "all"
            },
            "beta-plugin": {
              "outputPath": {
                "base": "dist/beta/front",
                "browser": ""
              },
              "index": {
                "input": "src/index.plugin.html",
                "output": "index.html"
              },
              "budgets": [
                {
                  "type": "anyComponentStyle",
                  "maximumWarning": "6kb"
                }
              ],
              "fileReplacements": [
                {
                  "replace": "src/environments/environment.ts",
                  "with": "src/environments/environment.beta-plugin.ts"
                }
              ],
              "outputHashing": "all"
            },
            "dev1": {
              "outputPath": {
                "base": "dist/dev1/frontStandalone",
                "browser": ""
              },
              "budgets": [
                {
                  "type": "anyComponentStyle",
                  "maximumWarning": "6kb"
                }
              ],
              "fileReplacements": [
                {
                  "replace": "src/environments/environment.ts",
                  "with": "src/environments/environment.dev1.ts"
                }
              ],
              "outputHashing": "all"
            },
            "dev2": {
              "outputPath": {
                "base": "dist/dev2/frontStandalone",
                "browser": ""
              },
              "budgets": [
                {
                  "type": "anyComponentStyle",
                  "maximumWarning": "6kb"
                }
              ],
              "fileReplacements": [
                {
                  "replace": "src/environments/environment.ts",
                  "with": "src/environments/environment.dev2.ts"
                }
              ],
              "outputHashing": "all"
            },
            "dev3": {
              "outputPath": {
                "base": "dist/dev3/frontStandalone",
                "browser": ""
              },
              "budgets": [
                {
                  "type": "anyComponentStyle",
                  "maximumWarning": "6kb"
                }
              ],
              "fileReplacements": [
                {
                  "replace": "src/environments/environment.ts",
                  "with": "src/environments/environment.dev3.ts"
                }
              ],
              "outputHashing": "all"
            },
            "dev4": {
              "outputPath": {
                "base": "dist/dev4/frontStandalone",
                "browser": ""
              },
              "budgets": [
                {
                  "type": "anyComponentStyle",
                  "maximumWarning": "6kb"
                }
              ],
              "fileReplacements": [
                {
                  "replace": "src/environments/environment.ts",
                  "with": "src/environments/environment.dev4.ts"
                }
              ],
              "outputHashing": "all"
            },
            "dev5": {
              "outputPath": {
                "base": "dist/dev5/frontStandalone",
                "browser": ""
              },
              "fileReplacements": [
                {
                  "replace": "src/environments/environment.ts",
                  "with": "src/environments/environment.dev5.ts"
                }
              ],
              "outputHashing": "all"
            },
            "dev6": {
              "outputPath": {
                "base": "dist/dev6/frontStandalone",
                "browser": ""
              },
              "fileReplacements": [
                {
                  "replace": "src/environments/environment.ts",
                  "with": "src/environments/environment.dev6.ts"
                }
              ],
              "outputHashing": "all"
            },
            "dev7": {
              "outputPath": {
                "base": "dist/dev7/frontStandalone",
                "browser": ""
              },
              "fileReplacements": [
                {
                  "replace": "src/environments/environment.ts",
                  "with": "src/environments/environment.dev7.ts"
                }
              ],
              "outputHashing": "all"
            },
            "dev8": {
              "outputPath": {
                "base": "dist/dev8/frontStandalone",
                "browser": ""
              },
              "fileReplacements": [
                {
                  "replace": "src/environments/environment.ts",
                  "with": "src/environments/environment.dev8.ts"
                }
              ],
              "outputHashing": "all"
            },
            "aws": {
              "outputPath": {
                "base": "dist/aws/frontStandalone",
                "browser": ""
              },
              "budgets": [
                {
                  "type": "anyComponentStyle",
                  "maximumWarning": "6kb"
                }
              ],
              "fileReplacements": [
                {
                  "replace": "src/environments/environment.ts",
                  "with": "src/environments/environment.aws.ts"
                }
              ],
              "optimization": true,
              "outputHashing": "all",
              "sourceMap": true,
              "namedChunks": false,
              "extractLicenses": true
            },
            "whitelabel": {
              "outputPath": {
                "base": "dist/whitelabel/frontStandalone",
                "browser": ""
              },
              "budgets": [
                {
                  "type": "anyComponentStyle",
                  "maximumWarning": "6kb"
                }
              ],
              "fileReplacements": [
                {
                  "replace": "src/environments/environment.ts",
                  "with": "src/environments/environment.whitelabel.ts"
                }
              ],
              "outputHashing": "all"
            }
          },
          "defaultConfiguration": ""
        },
        "serve": {
          "builder": "@angular-devkit/build-angular:dev-server",
          "options": {
            "buildTarget": "sembotAngular:build"
          },
          "configurations": {
            "production": {
              "buildTarget": "sembotAngular:build:production"
            },
            "production-plugin": {
              "buildTarget": "sembotAngular:build:production-plugin"
            },
            "local": {
              "buildTarget": "sembotAngular:build:local"
            },
            "beta": {
              "buildTarget": "sembotAngular:build:beta"
            },
            "beta-plugin": {
              "buildTarget": "sembotAngular:build:beta-plugin"
            },
            "dev1": {
              "buildTarget": "sembotAngular:build:dev1"
            },
            "dev2": {
              "buildTarget": "sembotAngular:build:dev2"
            },
            "dev3": {
              "buildTarget": "sembotAngular:build:dev3"
            },
            "dev4": {
              "buildTarget": "sembotAngular:build:dev4"
            },
            "aws": {
              "buildTarget": "sembotAngular:build:aws"
            },
            "whitelabel": {
              "buildTarget": "sembotAngular:build:whitelabel"
            }
          }
        },
        "extract-i18n": {
          "builder": "@angular-devkit/build-angular:extract-i18n",
          "options": {
            "buildTarget": "sembotAngular:build"
          }
        },
        "test": {
          "builder": "@angular-devkit/build-angular:karma",
          "options": {
            "main": "src/test.ts",
            "polyfills": "src/polyfills.ts",
            "tsConfig": "tsconfig.spec.json",
            "karmaConfig": "karma.conf.js",
            "assets": ["src/favicons", "src/assets"],
            "styles": ["src/styles/styles.scss"],
            "scripts": [],
            "stylePreprocessorOptions": {
              "includePaths": ["src/styles"]
            }
          }
        },
        "lint": {
          "builder": "@angular-eslint/builder:lint",
          "options": {
            "lintFilePatterns": ["src/**/*.ts", "src/**/*.component.html"]
          }
        },
        "e2e": {
          "builder": "@angular-devkit/build-angular:protractor",
          "options": {
            "protractorConfig": "e2e/protractor.conf.js",
            "devServerTarget": "sembotAngular:serve"
          },
          "configurations": {
            "production": {
              "devServerTarget": "sembotAngular:serve:production"
            }
          }
        }
      }
    }
  },
  "schematics": {
    "@angular-eslint/schematics:application": {
      "setParserOptionsProject": true
    },
    "@angular-eslint/schematics:library": {
      "setParserOptionsProject": true
    }
  }
}
